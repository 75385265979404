import { sendPostRequest } from '@/lib/actions';
import { Level } from '../types/api';
import { ApiEndpoints, AppConfig } from '../utils/AppConfig';

class LoggerService {
    private userId?: string | null;
    private logPrefix?: string | null
    private static instance: LoggerService;

    constructor(userId?: string | null, logPrefix: string = 'Client') {
        this.userId = userId;
        this.logPrefix = logPrefix;
    };

    static getInstance(): LoggerService {
        if (!LoggerService.instance) {
            LoggerService.instance = new LoggerService();
        }
        return LoggerService.instance;
    };

    setUserId(userId?: string | null) {
        this.userId = userId;
        return this;
    }

    setLogPrefix(logPrefix?: string | null) {
        this.logPrefix = logPrefix;
        return this;
    };

    info(logMessage: string, logContext?: object | null) {
        this.log(logMessage, Level.Info, logContext ?? null);
    };

    warning(logMessage: string, logContext?: object | null) {
        this.log(logMessage, Level.Warning, logContext ?? null);
    };

    error(logMessage: string | Error, logContext?: object | null) {
        this.log(logMessage, Level.Error, logContext ?? null);
    };

    critical(logMessage: string, logContext?: object | null) {
        this.log(logMessage, Level.Critical, logContext ?? null);
    };

    alert(logMessage: string, logContext?: object | null) {
        this.log(logMessage, Level.Alert, logContext ?? null);
    };

    debug(logMessage: string, logContext?: object | null) {
        this.log(logMessage, Level.Debug, logContext ?? null);
    };

    async log(logContent: string | Error, logLevel: Level, logContext: object | null) {
        try {
            let logMessage;

            if (logContent instanceof Error) {
                logMessage = `${logContent.message}`;    
            } else {
                logMessage = logContent;
            }

            if (this.logPrefix) {
                logMessage = `${this.logPrefix}: ${logMessage}`;
            }

            const body = { logMessage, logLevel, logContext, userId: this.userId };
            await sendPostRequest(ApiEndpoints.service.LOGGER, body, this.userId);
        } catch (error: any) {
            console.error(`Logger Error: ${error.message}`);
        }
    }
};

export { LoggerService };
export const createLogService = (userId?: string | null, logPrefix?: string): LoggerService => {
    return new LoggerService(userId, logPrefix);
};
