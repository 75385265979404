'use client';

import { useEffect } from "react";

const useAuthCheck = (checkStatus: () => Promise<boolean>) => {
    useEffect(() => {
        const interval = setInterval(async () => {
            const isAuthenticated = await checkStatus();
            if (!isAuthenticated) {
                clearInterval(interval);
            }
        }, 10 * 60 * 1000);

        return () => clearInterval(interval);
    }, [checkStatus]);
};

export default useAuthCheck;
