'use client';

import { AlertDialogAction, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from "@/components/ui/alert-dialog";
import { useAuth } from "@/contexts/auth-context";
import { useUi } from "@/contexts/ui-context";
import { ApiException } from "@/exceptions/ApiException";
import ApiTimeoutException from "@/exceptions/ApiTimeoutException";
import NotFoundException from "@/exceptions/NotFoundException";
import UnauthorizedException from "@/exceptions/UnauthorizedException";
import { ApiHandlerContext } from "@/hooks/useApiHandler";
import { createLogService, LoggerService } from "@/services/LoggerService";
import { IApiRedirect, IModalContent, IToastContent } from "@/types/api";
import { AlertDialogCancel, AlertDialogDescription } from "@radix-ui/react-alert-dialog";
import { useTranslations } from "next-intl";
import { useRouter } from "next/navigation";
import { ReactNode, useCallback } from "react";

export const ApiHandlerProvider: React.FC<{ children: ReactNode; userId?: string | null }> = ({ children, userId }) => {
    const trans = useTranslations('Common');
    const router = useRouter();
    const { logout } = useAuth();
    const { showToast, showModal } = useUi();
    const logger = new LoggerService(userId, 'ApiHandler');

    const handleApiResponse = useCallback((apiResponse: any) => {
        try {
            if (apiResponse instanceof ApiException) {
                handleApiException(apiResponse as ApiException);
            } else if (apiResponse instanceof Error) {
                handleApiError(apiResponse);
            } else {
                return apiResponse.data;
            }
        } catch (error: any) {
            logger.error(error);
        }
    }, []);

    const handleApiRedirect = useCallback((apiRedirect: IApiRedirect) => {
        try {
            router.push(apiRedirect.url);
        } catch (error: any) {
            logger.error(error, apiRedirect);
        }
    }, []);

    const handleApiModalAction = useCallback((content: IModalContent) => {
        showModal(
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{content.title}</AlertDialogTitle>
                    <AlertDialogDescription>{content.description}</AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>{trans('cancel')}</AlertDialogCancel>
                    {content.actionButton.url ? (
                        <AlertDialogAction onClick={() => router.push(`${content.actionButton.url}`)}>{content.actionButton.text ?? trans('continue')}</AlertDialogAction>
                    ) : (
                        <AlertDialogAction>Ok</AlertDialogAction>
                    )}
                </AlertDialogFooter>
            </AlertDialogContent>
        );
    }, [showModal, trans]);

    const handleApiToastAction = useCallback((content: IToastContent) => {
        try {
        } catch (error: any) {
            logger.error(error, content);
        }
    }, [showToast]);
    
    const handleApiException = useCallback((apiException: ApiException) => {
        try {
            switch (apiException.type) {
                case 'redirect':
                    handleApiRedirect(apiException.action as IApiRedirect);
                    break;
                case 'modal':
                    handleApiModalAction(apiException.action as IModalContent);
                    break;
                case 'toast':
                    handleApiToastAction(apiException.action as IToastContent);
                    break;
                default:
                    logger.warning(`Unsupported API exception type: ${apiException.action}.`, { exception: apiException.message });
                    break;
            }
        } catch (error: any) {
            logger.error(error);
        }
    }, [handleApiRedirect, handleApiModalAction, handleApiToastAction]);

    const handleApiError = useCallback((apiError: any): boolean => {
        try {
            if (apiError instanceof UnauthorizedException) {
                logout();
                return true;
            } else if (apiError instanceof ApiTimeoutException) {
                logger.warning(apiError.message);
                return true;
            } else if (apiError instanceof NotFoundException) {
                handleApiRedirect({ url: '/not-found' });
                logger.warning(apiError.message);
                return true;
            } else {
                handleApiRedirect({ url: '/error' });
                logger.error(`${apiError.message}. Redirect to the error page.`);
                return false;
            }
        } catch (error: any) {
            handleApiRedirect({ url: '/error' });
            logger.error(error);
            return false;
        }
    }, [logout, handleApiRedirect]);

    return (
        <ApiHandlerContext.Provider value={{ handleApiResponse, handleApiException, handleApiError }}>
            {children}
        </ApiHandlerContext.Provider>
    );
};