import React, { createContext, useContext } from "react";
import { ApiException } from "@/exceptions/ApiException";
import { IApiResponse } from "@/types/api";

type ApiHandlerContextType = {
    handleApiResponse: <T>(apiResponse: IApiResponse<T>) => void;
    handleApiException: (apiException: ApiException) => void;
    handleApiError: (apiError: any) => boolean;
};

export const ApiHandlerContext = createContext<ApiHandlerContextType | null>(null);

export const useApiHandler = () => {
    const context = useContext(ApiHandlerContext);
    if (!context) {
        throw new Error("useApiHandler must be used within ApiHandlerProvider");
    }
    return context;
};