'use client';

import { MenuIcon } from 'lucide-react';
import React, { useCallback } from 'react';
import { Button } from '../ui/button';
import { SheetClose, SheetContent, SheetDescription, SheetFooter, SheetHeader, SheetTitle } from '../ui/sheet';
import { useUi } from '@/contexts/ui-context';
import { useSidebar } from '../ui/sidebar';

const MenuButton: React.FC = () => {
    const { toggleSidebar } = useSidebar();
    
    return (
        <Button
            variant="outline"
            size="icon"
            className="shrink-0"
            onClick={toggleSidebar}
        >
            <MenuIcon className="size-[1.2rem]" />
        </Button>
    );
};

export default MenuButton;
