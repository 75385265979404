import { Loader2Icon } from 'lucide-react';
import React from 'react';

export const LoaderCustom = () => {
    return (
        <div className="flex items-center justify-center">
            <Loader2Icon className="size-6 animate-spin text-muted-foreground" />
        </div>
    );
};

export default LoaderCustom;
