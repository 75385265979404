'use client';

import { HomeIcon, SearchIcon, ShoppingBasketIcon, User2Icon } from "lucide-react";
import Link from "next/link";
import { Badge } from "../ui/badge";
import { usePathname } from "next/navigation";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";

const BottomNavBar: React.FC = () => {
    const trans = useTranslations('Common');
    const [totalItemsInCart, setTotalItemsInCart] = useState<number>(3);
    const pathname = usePathname();

    useEffect(() => {
        // const cartItemsCount = parseInt(localStorage.getItem('cartItemsCount') ?? "0", 10);
        // setTotalItemsInCart(cartItemsCount);
    }, []);

    // useEffect(() => {
    //     const handleStorageChange = () => {
    //         const cartItemsCount = parseInt(localStorage.getItem('cartItemsCount') ?? "0", 10);
    //         setTotalItemsInCart(cartItemsCount);
    //     };

    //     window.addEventListener("storage", handleStorageChange);

    //     return () => {
    //         window.removeEventListener("storage", handleStorageChange);
    //     };
    // }, []);

    return (
        <nav className="fixed bottom-0 w-full shadow-lg border-t md:hidden px-1 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/90">
            <div className="flex justify-around items-center py-2">
                <Link href="/" className="flex flex-col items-center">
                    <HomeIcon className={`size-6 ${pathname === '/' ? '' : 'text-muted-foreground'}`}/>
                    <span className={`text-xs mt-1 ${pathname === '/' ? 'text-foreground' : 'text-muted-foreground'}`}>{trans('home')}</span>
                </Link>

                <Link href="/search" className="flex flex-col items-center">
                    <SearchIcon className="text-muted-foreground size-6" />
                    <span className="text-xs text-muted-foreground mt-1">{trans('search')}</span>
                </Link>

                <Link href="/profile/cart" className="flex flex-col items-center relative">
                    <div className="relative">
                        <ShoppingBasketIcon className="text-muted-foreground size-6" />
                        <Badge variant="destructive" className={`absolute text-[9px] leading-none px-1 rounded-full -top-1.5 -right-2 ${totalItemsInCart === 0 ? 'bg-muted text-muted-foreground' : 'bg-destructive text-white'}`}>
                            {totalItemsInCart}
                        </Badge>
                    </div>
                    <span className="text-xs text-muted-foreground mt-1">{trans('cart')}</span>
                </Link>

                <Link href="/profile" className="flex flex-col items-center">
                    <User2Icon className={`size-6 ${pathname === '/profile' ? '' : 'text-muted-foreground'}`} />
                    <span className={`text-xs mt-1 ${pathname === '/profile' ? 'text-foreground' : 'text-muted-foreground'}`}>{trans('profile')}</span>
                </Link>
            </div>
        </nav>
    );
};

export default BottomNavBar;
