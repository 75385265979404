import { AppConfig } from '@/utils/AppConfig';
import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
};

export function getApiUrl(endpoint: string, params?: Record<string, string | number | boolean | null | undefined> | null): string {
    if (params) {
        const queryString = new URLSearchParams(
            Object.entries(params).reduce((acc, [key, value]) => {
                if (value != null) {
                    acc[key] = String(value);
                }
                return acc;
            }, {} as Record<string, string>)
        ).toString();

        if (queryString) {
            endpoint += `?${queryString}`;
        }
    }

    return `${AppConfig.apiUrl}/${endpoint}`;
};

export async  function sendClientRequest<T>(apiUrl: string, request: RequestInit) {

    try {
        const response = await fetch(apiUrl, request);
        
        if (response.ok) {
            const result = await response.json();
            return result;
        } else {
            throw new Error('Client Request failed');
        }
    } catch (error: any) {

    }
};