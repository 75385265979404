'use client';

import { IToastContent } from '@/types/api';
import type { ReactNode} from 'react';
import { createContext, useContext } from 'react';

type UiContextType = {
    isDesktop: boolean;
    isModalOpen: boolean;
    showModal: (content: ReactNode) => void;
    hideModal: () => void;
    isDialogOpen: boolean;
    showDialog: (content: ReactNode) => void;
    hideDialog: () => void;
    isDrawerOpen: boolean;
    showDrawer: (content: ReactNode) => void;
    hideDrawer: () => void;
    isSheetOpen: boolean;
    showSheet: (content: ReactNode) => void;
    hideSheet: () => void;
    showWindow: (content: ReactNode) => void;
    hideWindow: () => void;
    showToast: (content: IToastContent) => void;
    hideToast: (toastId: string) => void;
    showLoadingToast: (toastId: string) => void;
    showErrorToast: (toastId: string) => void;
};

export const UiContext = createContext<UiContextType | undefined>(undefined);

export const useUi = (): UiContextType => {
    const context = useContext(UiContext);
    if (!context) {
        throw new Error('useUi must be used within a UiProvider');
    }

    return context;
};
