import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/var/www/frontend/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\",\"latin-ext\",\"cyrillic\",\"cyrillic-ext\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\",\"adjustFontFallback\":false,\"variable\":\"--font-sans\"}],\"variableName\":\"nunitoSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["ApiHandlerProvider"] */ "/var/www/frontend/src/providers/api-handler-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/providers/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavProvider"] */ "/var/www/frontend/src/providers/nav-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/providers/redux-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/frontend/src/providers/ui-provider.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/frontend/src/styles/global.css");
