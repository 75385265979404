import React from 'react';

const TopHeader: React.FC = () => {
    return (
        <div className="w-full bg-card-foreground sm:hidden">
            <div className="mx-auto max-w-screen-2xl">
                TOP HEADER
            </div>
        </div>
    );
};

export default TopHeader;
