'use client';

import type { ReactNode } from 'react';
import React, { useState, useCallback, useLayoutEffect } from 'react';
import { ApiEndpoints } from '@/utils/AppConfig';
import { logDebug, logError, logInfo, sendGetRequest, sendPostRequest } from '@/lib/actions';
import { IApiAction, IJwtTokens, IRegistrationFormInputs, IUser } from '@/types/api';
import { useRouter } from 'next/navigation';
import { useDispatch, useSelector } from 'react-redux';
import { setIsAuthenticated, setIsRegistered } from '@/store/authSlice';
import { AuthContext } from '@/contexts/auth-context';
import useAuthCheck from '@/hooks/useAuthCheck';
import { jwtTokens } from '@/lib/jwtTokens';
import { LoggerService } from '@/services/LoggerService';
import { ApiException } from '@/exceptions/ApiException';
import UnauthorizedException from '@/exceptions/UnauthorizedException';
import { RootState } from '@/store/store';
import { useUi } from '@/contexts/ui-context';

type AuthProviderProps = {
    children: ReactNode;
    initialUser: IUser | null;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children, initialUser }) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const [user, setUser] = useState<IUser | null>(initialUser);
    const logger = new LoggerService(initialUser?.id, 'AuthProvider');
    const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
    const isRegistered = useSelector((state: RootState) => state.auth.isRegistered);

    const login = (user: IUser): boolean => {
        logInfo("HANDLE LOGIN");
        dispatch(setIsAuthenticated(true));
        logDebug('Setting is athenticated.');
        setUser(user);
        return true;
    };

    const logout = useCallback(async (): Promise<void> => {
        dispatch(setIsAuthenticated(false));
        await sendPostRequest(ApiEndpoints.auth.LOGOUT);
        jwtTokens.del();
        setUser(null);
        router.push('/');
    }, [router]);

    const toDashboard = useCallback((): void => {
        if (user) {
            router.push(`/profile/${user.id}`);
        } else {
            logout();
        }
    }, [user, logout]);

    const updateJwtTokens = useCallback(async (): Promise<boolean> => {
        try {
            const apiResponse = await sendGetRequest<IJwtTokens>(ApiEndpoints.auth.GET_TOKENS);
            if (apiResponse.success) { 
                jwtTokens.set(apiResponse.data as IJwtTokens);
                return true;
            } else {
                return false;
            }
        } catch (error: any) {
            logger.error(error);
            return false;
        }
    }, []);

    const register = async (formInputs: IRegistrationFormInputs) => {
        try {
            logInfo("REGISTER ACCESSED!!!!");
            const apiResponse = await sendPostRequest<IApiAction | null>(ApiEndpoints.auth.REGISTER, formInputs);

            if (apiResponse.success) {
                logInfo("REGISTRATION SUCCESSFUL");
                await updateJwtTokens();
                const user = await getAuthUser();
                if (user) {
                    if (login(user)) {
                        dispatch(setIsRegistered());
                        router.refresh();
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    logError("USER NOT RECEIVED.");
                    await logout();
                    return false;
                }
            } else {
                logError("REGISTRATION FAILED");
                throw new ApiException(apiResponse.data as IApiAction);
            }
        } catch (error: any) {
            logError("REGISTRATION ERROR", { message: error.message });
            if (error instanceof UnauthorizedException) {
                await logout();
                logger.error('unauthorized exception caught during registration process.');
                return false;
            } else if (!(error instanceof ApiException)) {
                logger.error(error, formInputs);
                throw error;
            } else {
                throw error;
            }
        }
    };

    const getAuthUser = useCallback(async (): Promise<IUser | null> => {
        try {
            const apiResponse = await sendGetRequest<IUser | IApiAction>(ApiEndpoints.auth.GET_USER);

            if (apiResponse.success) {
                return apiResponse.data as IUser;
            } else {
                throw new ApiException(apiResponse.data as IApiAction);
            }
        } catch (error: any) {
            if (error instanceof UnauthorizedException) {
                return null;
            } else {
                throw error;
            }
        }
    }, []);

    const checkStatus = useCallback(async (): Promise<boolean> => {
        if (!jwtTokens.isValid()) {
            if (!await updateJwtTokens()) {
                await logout();
                return false;
            }
        }

        try {
            const apiResponse = await sendGetRequest<boolean>(ApiEndpoints.auth.CHECK);
            return apiResponse.success;
        } catch (error: any) {
            logger.error(`error during checking status. ${error.message}`);
            await logout();
            return false;
        }
    }, [logout, updateJwtTokens]);

    useAuthCheck(checkStatus);

    const checkIfRegistered = useCallback(async (email: string): Promise<boolean> => {
        try {
            const apiResponse = await sendGetRequest<{ is_registered: boolean }>(ApiEndpoints.auth.CHECK_IF_REGISTERED, { email });
            return apiResponse.success;
        } catch (error: any) {
            logger.error(`checking email failed. ${error.message}`, { email });
            return false;
        }
    }, []);

    useLayoutEffect(() => {
        if (initialUser) login(initialUser);
    }, [initialUser]);
    
    return (
        <AuthContext.Provider value={{ login, logout, register, toDashboard, checkIfRegistered }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
