import { IApiAction } from "@/types/api";

class NotFoundException extends Error {
    action: IApiAction;

    constructor(apiUrl: string) {
        super(`Data not found by ${apiUrl} URL.`);
        
        this.action = { 
            type: 'redirect',
            action: {
                url: '/not-found'
            },
        };

        Object.setPrototypeOf(this, NotFoundException.prototype);
    }
};

export default NotFoundException;
