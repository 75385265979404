import { Level } from "@/types/api";

class ApiTimeoutException extends Error {
    logLevel: Level;
    logContext?: any;
    userId?: string | null;

    constructor(url: string, timeout: number, logContext?: any, userId?: string | null) {
        super(`Request to ${url} timed out after ${timeout}ms.`);
        this.logLevel = Level.Alert;
        this.logContext = logContext;
        this.userId = userId;
    }
};

export default ApiTimeoutException;
