'use client';

import type { ReactNode} from 'react';
import React, { useState, useCallback, useMemo } from 'react';
import { UiContext } from '../contexts/ui-context';
import LoaderCustom from '../components/custom/LoaderCustom';
import { AlertDialog } from '../components/ui/alert-dialog';
import { Dialog } from '../components/ui/dialog';
import { Drawer } from '../components/ui/drawer';
import { Sheet } from '../components/ui/sheet';
import { Toaster } from '../components/ui/sonner';
import { useMinWidthQuery } from '@/hooks/useMinWidthQuery';
import { IToastContent } from '@/types/api';
import { toast } from 'sonner';
import { logWarning } from '@/lib/actions';
import { useTranslations } from 'next-intl';

type UiProviderProps = {
    children: ReactNode;
};

const UiProvider: React.FC<UiProviderProps> = ({ children }) => {
    const trans = useTranslations('Common');
    const isDesktop = useMinWidthQuery(768);
    const [modalContent, setModalContent] = useState<ReactNode | null>(null);
    const [dialogContent, setDialogContent] = useState<ReactNode | null>(null);
    const [drawerContent, setDrawerContent] = useState<ReactNode | null>(null);
    const [sheetContent, setSheetContent] = useState<ReactNode | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);

    const showModal = useCallback((content: ReactNode) => {
        setModalContent(content);
        setIsModalOpen(true);
    }, []);

    const hideModal = useCallback(() => {
        setIsModalOpen(false);
        setModalContent(null);
    }, []);

    const showDialog = useCallback((content: ReactNode) => {
        setDialogContent(content);
        setIsDialogOpen(true);
    }, []);

    const hideDialog = useCallback(() => {
        setIsDialogOpen(false);
        setDialogContent(null);
    }, []);

    const showDrawer = useCallback((content: ReactNode) => {
        setDrawerContent(content);
        setIsDrawerOpen(true);
    }, []);

    const hideDrawer = useCallback(() => {
        setIsDrawerOpen(false);
        setDrawerContent(null);
    }, []);

    const showSheet = useCallback((content: ReactNode) => {
        setSheetContent(content);
        setIsSheetOpen(true);
    }, []);

    const hideSheet = useCallback(() => {
        setIsSheetOpen(false);
        setSheetContent(null);
    }, []);

    const showWindow = useCallback((content: ReactNode) => {
        if (isDesktop) {
            showDialog(content);
        } else {
            showDrawer(content);
        }
    }, [isDesktop, showDialog, showDrawer]);

    const hideWindow = useCallback(() => {
        hideDialog();
        hideDrawer();
    }, [hideDialog, hideDrawer]);

    const showToast = useCallback((content: IToastContent) => {
        const toastData = {
            id: content.toastId,
            type: content.type,
            description: content.description,
            dismissible: content.dismissible ?? false,
            closeButton: content.closeButton,
            duration: content.duration,
        };
        
        switch (content.type) {
            case 'error':
                toast.error(content.title, toastData);
                break;
            case 'warning':
                toast.warning(content.title, toastData);
                break;
            case 'info':
                toast.info(content.title, toastData);
                break;
            case 'message':
                toast.success(content.title, toastData);
                break;
            case 'success':
                toast.success(content.title, toastData);
                break;
            default:
                logWarning(`Unsupported toast type: ${content.type}.`, toastData);
                toast.message(content.title, toastData);
                break;
        }
    }, []);

    const hideToast = useCallback((toastId?: string) => {
        toast.dismiss(toastId);
    }, []);

    const showLoadingToast = useCallback(async (toastId: string) => {
        toast.loading(trans('loading'), {
            id: toastId,
            description: trans('please_wait'),
        })
    }, [trans]);

    const showErrorToast = useCallback((toastId: string) => {
        toast.error(trans('error_occurred'), {
            id: toastId,
            description: trans('something_went_wrong'),
            dismissible: true,
            closeButton: true,
            duration: 2000,
        })
    }, [trans]);

    const contextValue = useMemo(() => ({
        isDesktop,
        isModalOpen,
        showModal,
        hideModal,
        isDialogOpen,
        showDialog,
        hideDialog,
        isDrawerOpen,
        showDrawer,
        hideDrawer,
        isSheetOpen,
        showSheet,
        hideSheet,
        showWindow,
        hideWindow,
        showToast,
        hideToast,
        showLoadingToast,
        showErrorToast
    }), [
        isDesktop,
        isModalOpen,
        showModal,
        hideModal,
        isDialogOpen,
        showDialog,
        hideDialog,
        isDrawerOpen,
        showDrawer,
        hideDrawer,
        isSheetOpen,
        showSheet,
        hideSheet,
        showWindow,
        hideWindow,
        showToast,
        hideToast,
        showLoadingToast,
        showErrorToast,
    ]);

    return (
        <UiContext.Provider value={contextValue}>
            <Toaster richColors position={isDesktop ? 'top-right' : 'bottom-center'} />
            {isModalOpen && (
                <AlertDialog open={isModalOpen} onOpenChange={hideModal}>
                    {modalContent || <LoaderCustom />}
                </AlertDialog>
            )}
            {isDialogOpen && (
                <Dialog open={isDialogOpen} onOpenChange={hideDialog}>
                    {dialogContent || <LoaderCustom />}
                </Dialog>
            )}
            {children}
            {isSheetOpen && (
                <Sheet open={isSheetOpen} onOpenChange={hideSheet}>
                    {sheetContent || <LoaderCustom />}
                </Sheet>
            )}
            {isDrawerOpen && (
                <Drawer open={isDrawerOpen} onClose={hideDrawer} onDrag={hideDrawer}>
                    {drawerContent || <LoaderCustom />}
                </Drawer>
            )}
        </UiContext.Provider>
    );
};

export default UiProvider;
