import { IApiAction, IApiRedirect, IModalContent, IToastContent } from "@/types/api";

export class ApiException extends Error {
    type: 'redirect' | 'modal' | 'toast';
    action: IApiRedirect | IModalContent | IToastContent;

    constructor(apiAction: IApiAction) {
        super(`API exception occurred.`);
        this.type = apiAction.type;
        this.action = apiAction.action;
    }
};
