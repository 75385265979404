export enum Level {
    Critical = 'critical',
    Error = 'error',
    Alert = 'alert',
    Warning = 'warning',
    Info = 'info',
    Success = 'notice',
    Debug = 'debug',
};

export type IApiResponse<T> = {
    success: boolean;
    data: T | IApiAction;
};

export type IApiAction = {
    type: 'redirect' | 'modal' | 'toast';
    action: IApiRedirect | IModalContent | IToastContent;
};

export type IJwtTokens = {
    w_access_token: string | undefined;
    w_refresh_token: string | undefined;
    w_access_token_lifetime: number;
    w_refresh_token_lifetime: number;
};

export type IApiRedirect = {
    url: string;
};

export type IModalContent = {
    title: string;
    description: string;
    level: Level;
    actionButton: {
        url?: string;
        text?: string;
    },
};

export type IToastContent = {
    toastId: string;
    title: string;
    description: string | null;
    type: 'error' | 'warning' | 'info' | 'message' | 'success' | 'loading';
    dismissible?: boolean;
    closeButton?: boolean;
    duration?: number;
};

export type IUser = {
    id: string;
};

export type ICategory = {
    slug: string;
    category_name: string;
    position: number;
    image_url: string;
    thumb_url: string;
};

export type IProduct = {
    id: number;
    name: string;
    slug: string;
    current_price: number;
    initial_price: number;
    discount: number | false;
    image_url: string;
};

export type IProductDetails = {
    pid: string;
} & IProduct;

export type IProductPrices = {
    current: number;
    initial?: number | null;
    discount_perc?: number | null;
};

export type IPrices = {
    initial: number;
    final: number | null;
    discount_perc: number | null;
    currency?: string;
};

export type IRegistrationFormInputs = {
    email: string;
    password: string;
    confirmPassword: string;
};

export type ILoginFormInputs = {
    email: string;
    password: string;  
};
