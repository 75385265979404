import { ICategory } from "@/types/api";
import Link from "next/link";

const SubHeader: React.FC = () => {

    return (
        <></>
    );
};

export default SubHeader;
