import { IJwtTokens } from "@/types/api";
import { logError, logWarning } from "./actions";

export const jwtTokens = {
    set(jwtTokens: IJwtTokens) {
        document.cookie = `w_access_token=${jwtTokens.w_access_token}; path=/; secure; samesite=none; max-age=${jwtTokens.w_access_token_lifetime * 60}`;
        document.cookie = `w_refresh_token=${jwtTokens.w_refresh_token}; path=/; secure; samesite=none; max-age=${jwtTokens.w_refresh_token_lifetime * 60}`;
    },
    get(): IJwtTokens | null {
        const cookies = document.cookie.split("; ");

        const accessToken = cookies.find((row) => row.startsWith("w_access_token="));
        const refreshToken = cookies.find((row) => row.startsWith("w_refresh_token="));

        if (!accessToken || !refreshToken) {
            return null;
        }

        return {
            w_access_token: accessToken.split("=")[1],
            w_refresh_token: refreshToken.split("=")[1],
            w_access_token_lifetime: 0,
            w_refresh_token_lifetime: 0,
        };
    },
    getAccessToken(): string | null {
        const cookies = document.cookie.split("; ");
        
        const accessToken = cookies.find((row) => row.startsWith('w_acesss_token='));
        
        if (!accessToken) {
            return null;
        } else {
            return accessToken;
        }
    },
    del(): void {
        document.cookie = `w_access_token=; path=/; secure; samesite=none; max-age=0`;
        document.cookie = `w_refresh_token=; path=/; secure; samesite=none; max-age=0`;
    },
    isValid(): boolean {
        const tokens = this.get();

        if (!tokens?.w_access_token) {
            logWarning('jwtTokens: access token is missing.', null, null, false);
            return false;
        }

        try {
            const tokenParts = tokens.w_access_token.split(".");
            if (tokenParts.length < 2 || !tokenParts[1]) {
                logError('jwtTokens: access token is not properly formatted.');
                return false;
            }

            const payload = JSON.parse(atob(tokenParts[1]));
            const currentTime = Math.floor(Date.now() / 1000);

            if (!payload.exp || typeof payload.exp !== "number") {
                logWarning("jwtTokens: Access token is missing 'exp' field or it's invalid.");
                return false;
            }

            return payload.exp > currentTime;
        } catch (error: any) {
            logError('jwtTokens: failed to decode JWT.', error);
            return false;
        }
    }
};
