// src/store/slices/authSlice.ts
import { IUser } from '@/types/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface AuthState {
    isAuthenticated: boolean;
    isRegistered: boolean;
    user: IUser | null;
    token: string | null;
}

const initialState: AuthState = {
    isAuthenticated: false,
    isRegistered: false,
    user: null,
    token: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setIsRegistered: (state) => {
            state.isRegistered = true;
        },
        login: (state, action: PayloadAction<{ user: AuthState['user']; token: string }>) => {
            state.isAuthenticated = true;
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        logout: (state) => {
            state.isAuthenticated = false;
            state.user = null;
            state.token = null;
        },
    },
});

export const { login, logout, setIsAuthenticated, setIsRegistered } = authSlice.actions;
export default authSlice.reducer;
