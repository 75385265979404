'use client';

import { createContext, useContext } from 'react';

type NavContextType = {
    openMainMenu: () => void;
    closeMainMenu: () => void;
};

export const NavContext = createContext<NavContextType | undefined>(undefined);

export const useNav = (): NavContextType => {
    const context = useContext(NavContext);
    if (!context) {
        throw new Error('useNav must be used within a NavProvider');
    }

    return context;
};
