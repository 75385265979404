'use client';

import { IRegistrationFormInputs, IUser } from '@/types/api';
import type { ReactNode } from 'react';
import { createContext, useContext } from 'react';

type AuthContextType = {
    login: (user: IUser) => void;
    logout: () => Promise<void>;
    register: (formInputs: IRegistrationFormInputs) => Promise<boolean>;
    toDashboard: () => void;
    checkIfRegistered: (email: string) => Promise<boolean>;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within a AuthProvider');
    }

    return context;
};
