import type { LocalePrefixMode } from 'node_modules/next-intl/dist/types/src/routing/types';

const localePrefix: LocalePrefixMode = 'as-needed';

export const AppConfig = {
    name: 'Wlampi.Com',
    locales: ['en', 'ru'],
    defaultLocale: 'en',
    localePrefix,
    baseUrl: 'https://wlampi.com',
    apiUrl: 'https://api.tgonlineweb.xyz/api',
    api: {
        baseUrl: 'https://api.tgonlineweb.xyz',
        loggerEndpoint: `https://api.tgonlineweb.xyz/services/client-logger/`,
    },
    assets: {
        MAIN_LOGO_PATH: 'assets/images/logo.svg',
    },
    endpoints: {
        auth: {
            LOGIN: 'auth/login',
            SIGN_UP: 'auth/sign-up',
            LOGOUT: 'auth/logout',
            RECOVER: 'auth/recover',
        },
        banners: {
            GET_TOP_BANNERS: 'banners/get-top-banners',
        },
        products: {
            GET_RECENT: 'https://api.tgonlineweb.xyz/api/products/recent',
            GET_FAKE: 'https://api.tgonlineweb.xyz/api/products/fake',
            GET_PRODUCT_DETAILS: 'https://api.tgonlineweb.xyz/api/products/get-product-details/',
        },
        categories: {
            GET: 'https://api.tgonlineweb.xyz/api/categories/get',
        },
    },
};

export const ApiEndpoints = {
    auth: {
        LOGIN: 'auth/login',
        LOGOUT: 'auth/logout',
        REGISTER: 'auth/register',
        SIGN_IN: 'auth/sign-in',
        CHECK: 'auth/check',
        GET_TOKENS: 'auth/get-tokens',
        GET_USER: 'auth/get-auth-user',
        CHECK_IF_REGISTERED: 'auth/check-if-registered',
    },
    public: {
        static: {
            GET_BANNERS: 'public/static/get-banners',
            GET_CATEGORIES: 'public/static/get-categories',
            GET_INDEX_PAGE_DATA: 'public/static/get-index-page-data'
        },
        products: {
            GET_RECENT: 'public/products/get-recent',
            GET_BEST_OFFERS: 'public/products/get-best-offers',
            GET_POPULAR: 'public/products/get-popular',
            GET_DETAILS: 'public/products/get-details',
        },
        shops: {
            GET: 'public/shops/get',
        },
    },
    protected: {
        GET_USER: 'protected/user/get',
        GET_USER_PROFILE: 'protected/user/profile/get',
        SAVE_USER_PROFILE: 'protected/user/profile/save',
        EDIT_USER_PROFILE: 'protected/user/profile/edit',
        DELETE_USER_PROFILE: 'protected/user/profile/delete',

        GET_USER_SHOPS: 'protected/user/shops/get',
        SAVE_USER_SHOPS: 'protected/user/shops/save',
        EDIT_USER_SHOPS: 'protected/user/shops/edit',
        DELETE_USER_SHOPS: 'protected/user/shops/delete',

        GET_USER_ORDERS: 'protected/user/orders/get',
        SAVE_USER_ORDERS: 'protected/user/orders/save',
        EDIT_USER_ORDERS: 'protected/user/orders/edit',
        DELETE_USER_ORDERS: 'protected/user/orders/delete',
        PROCESS_USER_ORDERS: 'protectes/user/orders/process',

        GET_USER_PRODUCTS: 'protected/user/products/get',
        SAVE_USER_PRODUCTS: 'protected/user/products/save',
        EDIT_USER_PRODUCTS: 'protected/user/products/edit',
        DELETE_USER_PRODUCTS: 'protected/user/products/delete',
    },
    service: {
        LOGGER: 'service/logger',
        TEST_GET: 'service/test/get',
        TEST_POST: 'service/test/post',
    },
};
