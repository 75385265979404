'use client';

import { useTheme } from 'next-themes';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

const Logo: React.FC = () => {
    const { theme } = useTheme();

    return (
        <Link href="/">
            <Image
                src={"/assets/images/logo-fill.svg"}
                alt="logo"
                width={48}
                height={48}
            />
        </Link>
    );
};

export default Logo;
