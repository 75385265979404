'use client';

import { ShoppingCartIcon } from 'lucide-react';
import React from 'react';
import { Button } from '../ui/button';

const CartButton: React.FC = () => {
    return (
        <Button variant="outline" size="icon" className="shrink-0">
            <ShoppingCartIcon className="size-[1.2rem]" />
        </Button>
    );
};

export default CartButton;
