'use client';

import Footer from "@/components/custom/Footer";
import Header from "@/components/custom/Header";
import SubHeader from "@/components/custom/SubHeader";
import TopHeader from "@/components/custom/TopHeader";
import { AppSidebar } from "@/components/nav/AppSideBar";
import BottomNavBar from "@/components/nav/BottomNavBar";
import { SidebarProvider, SidebarInset } from "@/components/ui/sidebar";
import { NavContext } from "@/contexts/nav-context";
import { ReactNode, useCallback, useState } from "react";

export const NavProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isMainMenuOpen, setIsMainMenuOpen] = useState<boolean>(false);

    const openMainMenu = useCallback(() => {
        setIsMainMenuOpen(true);
    }, []);

    const closeMainMenu = useCallback(() => {
        setIsMainMenuOpen(false);
    }, []);

    return (
        <NavContext.Provider value={{ openMainMenu, closeMainMenu }}>
            <SidebarProvider>
                <AppSidebar />
                <SidebarInset>
                    <TopHeader />
                    <Header />
                    <SubHeader />
                    <main className="flex flex-1 flex-col items-center gap-4 p-4 pt-0">
                        {children}
                    </main>
                    <Footer />
                    <BottomNavBar />
                </SidebarInset>
            </SidebarProvider>
        </NavContext.Provider>
    );
};