'use client';

import React from 'react';
import { Button } from '../ui/button';
import CartButton from '../buttons/CartButton';
import MenuButton from '../buttons/MenuButton';
import { ModeToggle } from '../buttons/ModeToggle';
import Logo from './Logo';
import { HeartIcon, MailIcon, UserIcon } from 'lucide-react';
import { SidebarProvider, SidebarInset, SidebarTrigger } from '../ui/sidebar';
import { AutoCompleteSearch } from './AutoCompleteSearch';

const Header: React.FC = () => {
    return (
        <header className="sticky top-0 z-30 w-full border-b bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/80">
            <div className="mx-auto flex p-2 flex-row">
                <div className="flex flex-row w-full items-center space-x-2 justify-between">
                    <div className="flex flew-row items-center space-x-2">
                        <SidebarTrigger className="-ml-1" />
                        <Logo />
                        <MenuButton />
                        <Button variant="outline" size="icon" className="shrink-0">
                            <MailIcon className="size-[1.2rem]" />
                        </Button>
                        {/* <UserButton />
                        <CartButton /> */}
                    </div>
                    <div>
                        <AutoCompleteSearch options={[]} emptyMessage={''} />
                    </div>
                    <div className="flex flex-row space-x-2">
                        <CartButton />
                        <ModeToggle />
                        <Button variant="outline" size="icon" className="shrink-0">
                            <HeartIcon className="size-[1.2rem]" />
                        </Button>
                        <Button variant="outline" size="icon" className="shrink-0">
                            <UserIcon className="size-[1.2rem]" />
                        </Button>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
