import { IApiAction } from "@/types/api";

class UnauthorizedException extends Error {
    action: IApiAction;

    constructor(apiUrl: string) {
        super(`Unauthorized user request to ${apiUrl}.`);

        this.action = {
            type: 'redirect',
            action: {
                url: '/login'
            },
        };

        Object.setPrototypeOf(this, UnauthorizedException.prototype);
    }
};

export default UnauthorizedException;
